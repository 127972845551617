<script src="../../../node_modules/lib-flexible/flexible.js"></script>
<template>
  <!--机构端 校园筛查 筛查计划管理 筛查列表查询 -->
  <div class="page">
    <!-- <div class="warp">
      <div class="warp-header">
        <span>筛查列表查询</span>
        <el-select v-model="screeningListId" class="screeningSelect" size="mini" filterable :filter-method="searchScreening" placeholder="请选择筛查计划">
          <el-option
            v-for="(item,index) in screeningOptions"
            :key="item.id"
            :label="item.screeningName"
            :value="item.id"
            @click.native="changeScreening(index)"
          ></el-option>
        </el-select>
        <div style="flex-grow: 1"></div>
        <div class="warp-button">
          <el-button type="primary" size="mini" round @click="goTo('/detection/barCode',{id:screeningListId})">打印二维码</el-button>
          <el-button type="primary" size="mini" round @click="expScreeningPlan">导出EXCEL</el-button>
        </div>
      </div>
    </div> -->
    <div class="tabs-section">
      <el-tabs
        v-if="isSchoolId === 1"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="我创建的筛查计划" name="0"></el-tab-pane>
        <el-tab-pane label="学校上报的筛查计划" name="1"></el-tab-pane>
      </el-tabs>
    </div>

    <div class="header-container">
      <div class="header-section">
        <div class="select-section">
          <div class="header-input" v-if="activeName == 1">
            <el-select
              v-model="selectSchool"
              placeholder="请选择学校"
              size="mini"
              @change="getScreeningSelect"
            >
              <el-option
                v-for="item in schoolList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <el-select
            v-model="screeningListId"
            class="screeningSelect"
            size="mini"
            filterable
            @change="getSchool"
            placeholder="请选择筛查计划"
          >
            <el-option
              v-for="item in screeningOptions"
              :key="item.id"
              :label="item.screeningName"
              :value="item.id"
              @click.native="changeScreening(item)"
            ></el-option>
          </el-select>
        </div>
        <div class="button-section">
          <el-button
            type="primary"
            size="mini"
            round
            @click="goTo('/management/importScreeningData')"
            >导入数据</el-button
          >
          <el-button
            type="primary"
            size="mini"
            round
            @click="goTo('/detection/barCode', { id: screeningListId })"
            >打印二维码
          </el-button>
          <!-- <template slot-scope="scope"> -->
          <el-button type="primary" size="mini" round @click="expScreeningPlan" v-show="isSchoolId === 1">
             导出数据
          </el-button>
          <!-- <el-button type="primary" size="mini" round @click="expScreeningPlan"> -->
          <!-- <el-button type="primary" size="mini" round @click="exportPopup(scope.row)"> -->
          <el-button type="primary" size="mini" round @click="exportPopup" v-show="isSchoolId !== 1">
            导出数据
            <!-- <el-button type="text" size="mini" round @click="Visible = true">导出数据</el-button> -->
            <!-- <template>
                  <el-button type="text" @click="open">导出数据</el-button>
                </template> -->
          </el-button>
          <!-- </template> -->
        </div>
      </div>
    </div>

    <div class="header">
      <div class="header-switc">
        <div
          class="header-switc-button header-switc-button-left"
          :class="type ? '' : 'active'"
          @click="handleSwitc(0)"
        >
          待筛查
        </div>
        <div
          class="header-switc-button header-switc-button-right"
          :class="type ? 'active' : ''"
          @click="handleSwitc(1)"
        >
          已筛查
        </div>
      </div>
      <div class="header-input">
        <el-select
          v-if="activeName == 0"
          v-model="schoolId"
          class="mr-10"
          size="mini"
          filterable
          placeholder="请选择学校"
          @change="getGradeSelect"
        >
          <el-option
            v-for="item in schoolOptions"
            :key="item.value"
            :label="item.schoolName"
            :value="item.schoolId"
          ></el-option>
        </el-select>
        <el-select
          v-model="gradeId"
          clearable
          class="mr-10"
          size="mini"
          placeholder="请选择年级"
          @change="getClassSelect"
        >
          <el-option
            v-for="item in gradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="classId"
          clearable
          class="mr-10"
          size="mini"
          placeholder="请选择班级"
          @change="search"
        >
          <el-option
            v-for="item in classOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          maxlength="11"
          placeholder="请输入学生姓名搜索"
          size="mini"
          class="search mr-10"
          v-model="searchValue"
          style="width: 250px"
        >
        </el-input>
        <el-button type="primary" size="small" @click="search()"
          >提交</el-button
        >
        <el-button size="small" @click="resetForm()">重置</el-button>
      </div>
      <div class="header-search"></div>
    </div>

    <div class="content" v-loading="loadStatus">
      <div class="table-main" v-show="type ? false : true">
        <el-table
          :data="tablePageData"
          @selection-change="handleSelectionChange"
          :cell-style="rowClass"
          :max-height="maxHeight"
          :row-class-name="tableRowClassName"
          style="width: 100%"
        >
          <el-table-column type="selection" disabled> </el-table-column>
          <el-table-column
            label="学校信息"
            :formatter="formSchoolInfo"
            width="240"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="childrenName" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="sex" label="性别" align="center">
          </el-table-column>
          <el-table-column
            prop="studentId"
            label="学号"
            :formatter="formNull"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :formatter="formnation"
            prop="nation"
            label="民族"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="birthday"
            label="出生日期"
            :formatter="formatterDate"
            align="center"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="idCard"
            label="身份证号"
            width="200"
            :formatter="formNull"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center" width="200">
          </el-table-column>
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button
                size="small"
                type="text"
                @click="dialogArchives(scope.row)"
                >档案</el-button
              >
              <el-button size="small" type="text" @click="getScan(scope.row)"
                >条形码</el-button
              >
            </template>
          </el-table-column>
          <div slot="empty">
            <div :style="{ marginTop: '52px' }">
              <img src="@/assets/detection/image/404.png" alt="" width="333" />
            </div>
          </div>
        </el-table>
      </div>
      <div class="table-main" v-show="type ? true : false">
        <el-table
          :data="tablePageData"
          @selection-change="handleSelectionChange"
          :cell-style="rowClass"
          :max-height="maxHeight"
          :row-class-name="tableRowClassName"
          style="width: 100%"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column
            prop="visionText"
            label="视力"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="refractionText"
            label="屈光"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :formatter="formSchoolInfo"
            label="学校信息"
            width="240"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="childrenName"
            label="姓名"
            width="140"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="sex" label="性别" width="100" align="center">
          </el-table-column>
          <el-table-column
            prop="studentId"
            label="学号"
            :formatter="formNull"
            width="200"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :formatter="formnation"
            prop="nation"
            label="民族"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="birthday"
            label="出生日期"
            :formatter="formatterDate"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="idCard"
            label="身份证号"
            :formatter="formNull"
            width="220"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            width="140"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="visionDate"
            label="视力检查日期"
            :formatter="formatterDate"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="glasses"
            :formatter="glassesType"
            label="戴镜类型"
            width="200"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :formatter="visionData1"
            label="裸眼视力(右/左)"
            width="140"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :formatter="visionData2"
            label="戴镜视力(右/左)"
            width="140"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="refractiveDate"
            :formatter="formatterDate"
            label="屈光检查日期"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :formatter="formDs"
            label="球镜(右/左)"
            width="140"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :formatter="formDc"
            label="柱镜(右/左)"
            width="140"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :formatter="formAxis"
            label="轴位(右/左)"
            width="140"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :formatter="formSpecialVision"
            prop="specialVision"
            label="视力特殊情况"
            width="140"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            width="150"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                size="small"
                type="text"
                @click="dialogArchives(scope.row)"
                >档案</el-button
              >
              <el-button size="small" @click="getScan(scope.row)" type="text"
                >条形码</el-button
              >
            </template>
          </el-table-column>
          <div slot="empty">
            <div :style="{ marginTop: '52px' }">
              <img src="@/assets/detection/image/404.png" alt="" width="333" />
            </div>
          </div>
        </el-table>
      </div>
      <div class="table-page">
        <el-button
          :type="batchNum ? 'primary' : 'disabled'"
          :disabled="batchNum ? false : true"
          size="mini"
          class="delete-button"
          round
          @click="handleDelete(1)"
          >批量删除({{ batchNum }})
        </el-button>
        <pagination
          :current-page="startPage"
          :total="totalPage"
          @currentChange="handleCurrentChange"
          @sizeChange="setPageSize"
        ></pagination>
      </div>
      <el-dialog title="档案" class="archives" :visible.sync="dialogVisible">
        <el-form size="mini">
          <el-form-item label="姓名：">
            <div>{{ archives.childrenName }}</div>
          </el-form-item>
          <el-form-item label="性别：">
            <div>{{ archives.sex }}</div>
          </el-form-item>
          <el-form-item label="手机号：">
            <div>{{ archives.phone }}</div>
          </el-form-item>
          <el-form-item label="出生日期：">
            <div>{{ archives.birthday }}</div>
          </el-form-item>
          <el-form-item label="学校信息：">
            <div>{{ archives.schoolInfo }}</div>
          </el-form-item>
        </el-form>
        <div class="archives__title">
          <div class="title__line"></div>
          <div class="title__text">视力筛查</div>
        </div>
        <table
          class="dialog-table"
          border="1"
          cellpadding="0"
          cellspacing="0"
          bordercolor="#6e90bd"
          bordercolordark="#ffffff"
          style="width: 100%"
        >
          <tr>
            <th style="width: 30%; background: #f1f1f1"></th>
            <th style="width: 40%">右眼</th>
            <th style="width: 40%">左眼</th>
          </tr>
          <tr>
            <td style="background: #f1f1f1">裸眼</td>
            <!-- <td>{{ archives.glassesType ? '-' : archives.rightVisionData }}</td>
            <td>{{ archives.glassesType ? '-' : archives.leftVisionData }}</td> -->
            <td>{{ archives.rightVisionData }}</td>
            <td>{{ archives.leftVisionData }}</td>
          </tr>
          <tr>
            <td style="background: #f1f1f1">戴镜</td>
            <!-- <td>{{ archives.glassesType ? archives.rightVisionData : '-' }}</td> -->
            <!-- <td>{{ archives.glassesType ? archives.leftVisionData : '-' }}</td> -->
            <!-- <td>{{ archives.glassesType ? archives.wearRightVisionData : '-' }}</td>
            <td>{{ archives.glassesType ? archives.wearLeftVisionData : '-' }}</td> -->
            <td>{{ archives.wearRightVisionData }}</td>
            <td>{{ archives.wearLeftVisionData }}</td>
          </tr>
        </table>
        <div class="archives__title">
          <div class="title__line"></div>
          <div class="title__text">屈光度筛查</div>
        </div>
        <table
          class="dialog-table"
          border="1"
          cellpadding="0"
          cellspacing="0"
          bordercolor="#6e90bd"
          bordercolordark="#ffffff"
          style="width: 100%"
        >
          <tr>
            <th style="width: 30%; background: #f1f1f1"></th>
            <th style="width: 40%">右眼</th>
            <th style="width: 40%">左眼</th>
          </tr>
          <tr>
            <td style="background: #f1f1f1">球镜</td>
            <td>{{ archives.refractiveRightDs }}</td>
            <td>{{ archives.refractiveLeftDs }}</td>
          </tr>
          <tr>
            <td style="background: #f1f1f1">柱镜</td>
            <td>{{ archives.refractiveRightDc }}</td>
            <td>{{ archives.refractiveLeftDc }}</td>
          </tr>
          <tr>
            <td style="background: #f1f1f1">轴位</td>
            <!-- <td>{{ archives.glassesType }}</td>
            <td>{{ archives.glassesType }}</td> -->
            <td>{{ archives.refractiveRightAxis }}</td>
            <td>{{ archives.refractiveLeftAxis }}</td>
          </tr>
          <tr>
            <td style="background: #f1f1f1">SE</td>
            <td>{{ archives.esRight }}</td>
            <td>{{ archives.esLeft }}</td>
          </tr>
        </table>
      </el-dialog>

      <el-dialog
        title="条形码"
        class="barcode"
        width="500px"
        center
        :visible.sync="dialogBarcode"
      >
        <div
          id="barcodeImg"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <svg id="barcode" style="width: 400px"></svg>
          <div class="textCont" style="text-align: center">
            <p>{{ barcodeObj.grade }}</p>
            <p>{{ barcodeObj.className }}</p>
            <p>{{ barcodeObj.childrenName }} {{ barcodeObj.sex }}</p>
            <p>{{ screeningObj.screeningName }}</p>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="
              dialogBarcode = false
              barcodeObj = {}
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="downLoadQcode">下 载</el-button>
        </span>
      </el-dialog>
      <!-- 导出数据提示框 -->
      <div class="dialog-export">
        <!-- <el-dialog title="| 提示" :visible.sync="dialogDaochu" width="32%" margin-top="30vh"> -->
        <el-dialog title="| 提示" :visible.sync="dialogDaochu" width="520px" margin-top="30vh">
          <div class="export flex-col"></div>
          <span class="export-banb">请选择导出数据版本：</span>
          <div class="banb-edition">
            <el-row :gutter="20">
              <el-col :span="16" :offset="4">
                <div class="grid-content bg-purple export-edition" @click="expScreeningPlan">
                  <span class="info13">通用版</span>
                  <div class="serase-word">
                    <span class="word21">导出数据</span>
                    <img
                      class="icon9"
                      referrerpolicy="no-referrer"
                      src="@/assets/detection/icon-dc.png"
                    />
                  </div>
                </div>
                </el-col>
            </el-row>
            <!-- <div class="export-edition flex-row" @click="expScreeningPlan">
              <span class="info13">通用版</span>
              <div class="serase-word">
                <span class="word21">导出数据</span>
                <img
                  class="icon9"
                  referrerpolicy="no-referrer"
                  src="@/assets/detection/icon-dc.png"
                />
              </div>
            </div> -->
          </div>
          <el-row :gutter="20">
              <el-col :span="16" :offset="4">
                <div class="grid-content bg-purple banb-edition" @click="expKaramayEdition">
                  <div class="outer22 export-edition flex-row">
                    <span class="info13">克拉玛依版</span>
                    <div class="serase-word">
                      <span class="word21">导出数据</span>
                      <img
                        class="icon10"
                        referrerpolicy="no-referrer"
                        src="@/assets/detection/icon-dc.png"
                      />
                    </div>
                  </div>
                </div>
                </el-col>
            </el-row>
          <!-- <div class="banb-edition" @click="expKaramayEdition">
            <div class="outer22 export-edition flex-row">
              <span class="info13">克拉玛依版</span>
              <div class="serase-word">
                <span class="word21">导出数据</span>
                <img
                  class="icon10"
                  referrerpolicy="no-referrer"
                  src="@/assets/detection/icon-dc.png"
                />
              </div>
            </div>
          </div> -->
          <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="dialogDaochu = false">取 消</el-button>
            <el-button type="primary" @click="dialogDaochu = false">确 定</el-button>
          </span> -->
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectService from '@/globals/service/detection/select.js'
import screeningService from '@/globals/service/detection/screening.js'
import api from '@/globals/request/api.js'
import qs from 'qs'
import Moment from 'moment'

var JsBarcode = require('jsbarcode')
import html2canvas from 'html2canvas'
import storage from '@/globals/storage/index.js'
import organizationService from '@/globals/service/management/organization.js'

export default {
  data () {
    return {
      maxHeight: document.documentElement.clientHeight - 320,
      loadStatus: true,
      selectSchool: '',
      schoolList: null,
      isSchoolId: null,
      // 自建和上传
      activeName: 0,
      screeningListId: '',
      screeningOptions: [],
      screeningLst: [],
      screeningObj: {},
      schoolId: '',
      gradeId: null,
      classId: null,
      schooldis: false,
      gradedis: false,
      classdis: false,
      school: '',
      nickName: '',
      newGradeId: null,
      schoolClassId: null,
      schoolOptions: [],
      gradeOptions: [],
      classOptions: [],
      archives: {},
      dialogVisible: false,
      dialogDaochu:false,
      dialogBarcode: false,
      searchValue: '',
      total: 0,
      totalPage: 0,
      startPage: 1, // 当前页码
      pageSize: 8,
      startPageScreening: 1,
      pageSizeScreening: 1000,
      tableData: [],
      tablePageData: [],
      multipleSelection: [],
      batchNum: 0,
      type: 0,
      barcodeObj: {},
      selectRow: []
    }
  },
  created () {
    const schoolId = storage.get('schoolId')
    this.isSchoolId = schoolId ? 0 : 1
    this.getSchoolList()
  },
  mounted () {
    const query = this.$route.query
    // console.log('query', query)
    if (query.row) {
      this.screeningObj = query.row
      this.getScreeningSelect(query.row)
    } else {
      this.getScreeningSelect()
    }
  },
  methods: {
     open() {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          // cancelButtonText: '取消',
          // type: 'warning'
        // }).then(() => {
        //   this.$message({
        //     type: 'success',
        //     message: '删除成功!'
        //   });
        // }).catch(() => {
        //   this.$message({
        //     type: 'info',
        //     message: '已取消删除'
        //   });
        });
      },
    resetForm () {
      this.gradeId = ''
      this.classId = ''
      this.searchValue = ''
      this.search()
    },
    getSchoolList () {
      let data
      selectService.school({
        startPage: 1,
        pageSize: 1000
      }).then(res => {
        // const schoolId = storage.get('schoolId')
        // if (schoolId) {
        //   data = res.data.schoolList.filter(item => item.id === schoolId)
        // } else {
        //   data = res.data.schoolList
        // }
        data = res.data.schoolList
        data = data.map(item => {
          return {
            value: item.id,
            label: item.schoolName
          }
        })
        this.schoolList = data
        if (this.schoolList[0]) {
          this.selectSchool = this.schoolList[0].value
          // this.getListData()
        }
      })
    },
    getSchool () {
      if (!this.screeningListId) return
      const schoolId = storage.get('schoolId')
      this.schoolOptions = []
      this.gradeOptions = []
      this.classOptions = []
      organizationService.getSchoolAndUsers({ id: this.screeningListId }).then(res => {
        let data = []
        res.data.screeningVoList.forEach(item => {
          if (!data.some(items => items.schoolId === item.schoolId)) {
            if (Number(schoolId)) {
              if (Number(schoolId) === item.schoolId) {
                data.push({
                  schoolId: item.schoolId,
                  schoolName: item.schoolName
                })
              }
            } else {
              data.push({
                schoolId: item.schoolId,
                schoolName: item.schoolName
              })
            }
          }
        })
        this.schoolOptions = data
        if (this.schoolOptions[0]) {
          this.schoolId = this.schoolOptions[0].schoolId
          this.getGradeSelect()
        }
      })
    },
    handleClick () {
      this.screeningListId = null
      this.tablePageData = []
      this.schoolId = null
      this.gradeId = null
      this.classId = null
      this.schoolOptions = []
      this.gradeOptions = []
      this.classOptions = []
      this.searchValue = ''
      this.selectSchool = null
      this.getScreeningSelect()
    },
    setPageSize (value) {
      this.pageSize = value
      this.getListData()
    },
    goTo (path, query) {
      this.$router.push({
        path: path,
        query: query
      })
    },
    dialogArchives (row) {
      // console.log(row)
      let obj = {
        'birthday': this.formatterDate('', '', row.birthday),
        'childrenName': row.childrenName || '-',
        'sex': row.sex || '-',
        'phone': row.phone || '-',
        'schoolInfo': (row.schoolName + row.grade + row.className) || '-',
        'rightVisionData': row.rightVisionData || '-',
        'leftVisionData': row.leftVisionData || '-',
        'glassesType': row.glassesType || '-',
        'refractiveLeftDs': row.refractiveLeftDs || '-',
        'refractiveRightDs': row.refractiveRightDs || '-',
        'refractiveLeftDc': row.refractiveLeftDc || '-',
        'refractiveRightDc': row.refractiveRightDc || '-',
        'refractiveLeftAxis': row.refractiveLeftAxis || '-',
        'wearLeftVisionData': row.wearLeftVisionData || '-',
        'wearRightVisionData': row.wearRightVisionData || '-',
        'refractiveRightAxis': row.refractiveRightAxis || '-',
        'esLeft': parseFloat((row.refractiveLeftDs + row.refractiveLeftDc / 2).toFixed(2)) || '-',
        'esRight': parseFloat((row.refractiveRightDs + row.refractiveRightDc / 2).toFixed(2)) || '-',
      }
      // console.log('obj', obj)

      this.archives = obj
      this.dialogVisible = true
    },
    getScreeningSelect (query) {
      // const schoolId = storage.get('schoolId')
      // console.log(schoolId)
      // if (schoolId) {
      //   this.searchScreening('')
      //   return
      // }
      const data = {
        type: this.activeName,
        'startPage': this.startPageScreening,
        'pageSize': this.pageSizeScreening
      }
      if (this.selectSchool) data.schoolId = this.selectSchool
      selectService.screening(data).then(res => {
        // let screeningOptions
        let screeningOptions = []
        if (res.data.list) {
          res.data.list.forEach(item => {
            const zhi = screeningOptions.some(items => item.id === items.id)
            if (!zhi) {
              screeningOptions.push(item)
            }
          })
        }
        // if (schoolId) screeningOptions = screeningOptions.filter(item => {
        //   return Number(item.schoolId) === Number(schoolId)
        // })
        // if (!schoolId) screeningOptions = res.data.list || []
        // screeningOptions = res.data.list || []
        // if(screeningOptions.length>0){
        //   let row = {}
        //   if(query){
        //     row = this.screeningObj
        //   }else{
        //     row = screeningOptions[0];
        //   }
        //   this.screeningObj = row
        //   this.screeningListId = row.id
        //   if(row.schoolId){
        //     this.schoolOptions = [{
        //       "value":row.schoolId,
        //       "label":row.schoolName
        //     }]
        //     this.schooldis = true
        //     this.school = row.schoolId;
        //     this.schoolId = row.schoolId;
        //     this.getGradeSelect(row)
        //   }
        //   if(row.gradeId){
        //     this.gradeId = row.gradeId;
        //     this.newGradeId = row.gradeId;
        //     this.gradedis = true
        //   }
        //   if(row.classId){
        //     this.classId = row.classId;
        //     this.schoolClassId = row.classId;
        //     this.classdis = true
        //   }
        //   this.getListData()
        // }
        this.screeningOptions = screeningOptions
        if (this.screeningOptions[0]) {
          this.screeningListId = this.screeningOptions[0].id
          this.getSchool()
        }
        this.getListData()
      })
    },
    searchScreening (row) {
      screeningService.search({ 'name': row }).then(res => {
        this.screeningOptions = res.data || []
      })
    },
    changeScreening (row) {
      this.startPage = 1
      this.schoolId = ''
      this.gradeId = null
      this.classId = null
      // this.schooldis = false
      // this.gradedis = false
      // this.classdis = false
      this.school = ''
      this.newGradeId = null
      this.schoolClassId = null
      this.screeningObj = row
      this.screeningListId = row.id
      if (row.schoolId) {
        // this.schoolOptions = [{
        //   "value":row.schoolId,
        //   "label":row.schoolName
        // }]
        this.schooldis = true
        this.school = row.schoolId
        this.schoolId = row.schoolId
        this.getGradeSelect(row)
      }
      if (row.gradeId) {
        this.gradeId = row.gradeId
        this.newGradeId = row.gradeId
        this.gradedis = true
      }
      if (row.classId) {
        this.classId = row.classId
        this.schoolClassId = row.classId
        this.classdis = true
      }
      // this.searchScreening('')
      this.getListData()
    },
    getGradeSelect (row) {
      if (!this.schoolId) {
        return false
      }
      this.gradeOptions = []
      this.classOptions = []
      if (row && row.gradeId) {
        this.gradeId = row.gradeId
        this.gradedis = true
      } else {
        this.gradeId = null
        this.classId = null
      }
      selectService.grade({ 'schoolId': this.schoolId + '' }).then(res => {
        // console.log('res', res)
        let gradeList = res.data
        let gradeOptions = []
        gradeList.forEach(item => {
          gradeOptions.push({
            'value': item.id,
            'label': item.gradeName
          })
        })
        this.gradeOptions = gradeOptions
        this.getClassSelect(row)
        this.search()
      })
    },
    getClassSelect (row) {
      if (!this.gradeId) {
        return false
      }
      this.classOptions = []
      if (row && row.classId) {
        this.classId = row.classId
        this.classdis = true
      } else {
        this.classId = null
      }
      selectService.class({ 'gradeId': this.gradeId + '' }).then(res => {
        let classList = res.data
        let classOptions = []
        classList.forEach(item => {
          classOptions.push({
            'value': item.id,
            'label': item.className
          })
        })
        this.classOptions = classOptions
        this.search()
      })
    },
    getListData () {
      let that = this
      // if(this.nickName &&  !this.schoolClassId){
      //   this.$message.error('请先选择班级后再姓名搜索');
      // }
      if (!this.schoolId) {
        return
      }
      if (!this.screeningListId) {
        return this.$message({
          type: 'info',
          message: '请选择筛查计划'
        })
      }
      let jsonData = {
        'id': this.screeningListId,
        'startPage': this.startPage,
        'pageSize': this.pageSize,
        'gradeId': this.newGradeId,
        'schoolId': this.schoolId,
        'classId': this.schoolClassId,
        'name': this.nickName,
        type: this.type
      }
      this.loadStatus = true
      screeningService.ScreenedVo(jsonData).then(res => {
        // console.log('res1', res)
        if (res.data.totalPage) {
          this.totalPage = res.data.totalPage
          that.total = res.data.totalPage * that.pageSize
          if (that.type) {
            that.tablePageData = res.data.haveScreenedRespList
          } else {
            that.tablePageData = res.data.noScreeningList
          }
        } else {
          this.total = 0
          this.totalPage = 0
          this.tablePageData = []
        }
        this.loadStatus = false
      })
    },
    search () {
      this.startPage = 1
      this.school = this.schoolId
      this.newGradeId = this.gradeId
      this.schoolClassId = this.classId
      this.nickName = this.searchValue
      this.getListData()
    },
    handleDelete (type) {
      if (type) {
        this.$confirm('请确认要全部删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const testChildrenList = []
          this.multipleSelection.map(item => {
            testChildrenList.push(item.childrenId)
          })
          screeningService.del({
            testChildrenList,
            screeningId: this.screeningListId
          }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getListData()
          })

          // this.$notify.error({
          //   title: '请求错误400',
          //   message: '失败原因：该筛查计划已经有筛查数据，请删除 相关数据后再删除！'
          // })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }
    },
    handleSwitc (type) {
      if (this.type == type || this.loadStatus) {
        return false
      }
      this.loadStatus = true
      this.type = type
      // this.schoolId = this.screeningObj.schoolId
      // this.gradeId = this.screeningObj.gradeId
      // this.classId = this.screeningObj.classId
      this.searchValue = ''
      this.tablePageData = []
      this.search()
    },
    handleCurrentChange (val) {
      this.startPage = val
      this.getListData()
    },
    formNull (row, column, cellValue) {
      if (cellValue === null || cellValue === '') return '-'
      if (cellValue == '') {
        return '-'
      } else {
        return cellValue
      }
    },
    formnation(row, column, cellValue){
      if (cellValue === null || cellValue === '') return '-'
      if (cellValue == '') {
        return '-'
      } else {
        return cellValue
      }
    },
    formSpecialVision (row, column, cellValue) {
      if (cellValue == 1) {
        return '障碍'
      } else if (cellValue == 0) {
        return '无'
      } else if (cellValue == '' || cellValue == null) {
        return '-'
      } else {
        return cellValue
      }
    },
    formSchoolInfo (row, column, cellValue) {
      if (row.className) {
        return row.schoolName + ' / ' + row.grade + ' / ' + row.className
      } else if (row.grade) {
        return row.schoolName + ' / ' + row.grade
      } else if (row.schoolName) {
        return cellValue
      } else {
        return '-'
      }
    },
    formatterDate (row, column, cellValue) {
      if (cellValue === null) return '-'
      if (!cellValue) {
        return '-'
      } else {
        return Moment(parseInt(cellValue)).format('YYYY-MM-DD')
      }
    },
    glassesType (row, column, cellValue) {
      if (cellValue == 1) {
        return '框架眼镜'
      } else if (cellValue == 2) {
        return '隐形眼镜'
      } else if (cellValue == 3) {
        return '夜戴角膜塑形镜'
      } else if (cellValue == 0) {
        return '不戴镜'
      }
    },
    visionData1 (row) {
      // if (row.glassesType != 0) {
      if (row.leftVisionData === null && row.rightVisionData === null) {
        return '-'
      } else if (row.leftVisionData === 0 && row.rightVisionData === 0) {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.leftVisionData) {
          left = row.leftVisionData
        }
        if (row.rightVisionData) {
          right = row.rightVisionData
        }
        return right + ' / ' + left
      }
    },
    visionData2 (row) {
      // console.log(row.wearLeftVisionData, row.wearRightVisionData);
      // if (row.glassesType == 0) {
      //   return '-'
      if (row.wearLeftVisionData === null && row.wearRightVisionData === null){
        return '-'
      } else if (row.wearLeftVisionData === 0 && row.wearRightVisionData === 0) {
        return '-'
      } else {
        let left = '-'
        let right = '-'
        if (row.wearLeftVisionData) {
          left = row.wearLeftVisionData
        }
        if (row.wearRightVisionData) {
          right = row.wearRightVisionData
        }
        return right + ' / ' + left
      }
    },
    formDs (row) {
      let left = '0'
      let right = '0'
      if (row.refractiveLeftDs) {
        left = row.refractiveLeftDs
      }
      if (row.refractiveRightDs) {
        right = row.refractiveRightDs
      }
      return right + ' / ' + left

    },
    formDc (row) {
      let left = '0'
      let right = '0'
      if (row.refractiveLeftDc) {
        left = row.refractiveLeftDc
      }
      if (row.refractiveRightDc) {
        right = row.refractiveRightDc
      }
      return right + ' / ' + left
    },
    formAxis (row) {
      let left = '0'
      let right = '0'
      if (row.refractiveLeftAxis) {
        left = row.refractiveLeftAxis
      }
      if (row.refractiveRightAxis) {
        right = row.refractiveRightAxis
      }
      return right + ' / ' + left
    },
    tableRowClassName ({
      row,
      rowIndex
    }) {
      if (rowIndex % 2 == 0) {
        return 'single-row'
      } else {
        return 'double-row'
      }
    },
    handleSelectionChange (rows) {
      this.multipleSelection = rows
      this.batchNum = rows.length
    },
    // 通用导出数据
    expScreeningPlan () {
      let jsonObj = {
        id: this.screeningListId,
        type: this.type,
        schoolId: this.schoolId,
        // uid: window.sessionStorage.getItem('uid'),
        // loginKey: window.sessionStorage.getItem('loginKey'),
        uid: window.localStorage.getItem('uid'),
        loginKey: window.localStorage.getItem('loginKey'),
      }
      window.location.href = api.exportStudents + '?' + qs.stringify(jsonObj)
      this.dialogDaochu = false
    },
    // 克拉玛依版导出数据
    expKaramayEdition(row){
       this.loadStatus = true
      this.type = 1
      // this.schoolId = this.screeningObj.schoolId
      // this.gradeId = this.screeningObj.gradeId
      // this.classId = this.screeningObj.classId
      this.searchValue = ''
      this.tablePageData = []
      this.search()
      this.getClassSelect(row)

      let jsonObj = {
        id: this.screeningListId,
        type: 1,
        schoolId: this.schoolId,
        newGradeId: this.gradeId,
        schoolClassId: this.classId,
        // uid: window.sessionStorage.getItem('uid'),
        // loginKey: window.sessionStorage.getItem('loginKey'),
        uid: window.localStorage.getItem('uid'),
        loginKey: window.localStorage.getItem('loginKey'),
      }
        //  console.log(jsonObj)
      window.location.href = api.exkaramay + '?' + qs.stringify(jsonObj)
      // window.location.href = StudentsService.exkaramay + '?' + qs.stringify(jsonObj)
      // console.log(window.location.href)
      this.dialogDaochu = false
    },
    exportPopup(row){
      this.dialogDaochu = true
    },
    // 查看条形码
    getScan (row) {
      // console.log('row', row.childrenId)
      this.barcodeObj = row
      this.dialogBarcode = true
      this.$nextTick(function () {
        let str = row.childrenId + '#' + this.screeningListId
        JsBarcode('#barcode', str, {
          displayValue: false,
        })
      })
    },
    downLoadQcode () {
      html2canvas(document.querySelector('#barcodeImg')).then(canvas => {
        let dataURL = canvas.toDataURL('image/png')
        if (dataURL !== '') {
          let alink = document.createElement('a')
          alink.href = dataURL
          alink.download = this.barcodeObj.childrenName + '.png'
          alink.click()
        }
      })
      this.dialogBarcode = false
    },
    rowClass ({
      row,
      rowIndex
    }) {
      if (this.selectRow.includes(row.childrenId)) {
        return { 'background-color': 'rgba(185, 221, 249, 0.75)' }
      }
    },
  },
  watch: {
    multipleSelection (data) {
      this.selectRow = []
      if (data.length > 0) {
        data.forEach((item, index) => {
          this.selectRow.push(item.childrenId)
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  padding: 10px 20px 0;
  background-color: #fff;
  min-height: 100%;
  width: 100%;
  padding-bottom: 20px;
}

.header-container {
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;

  .header-section {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .select-section {
      display: flex;
      align-items: center;

      .route-name {
        line-height: 43px;
        font-size: 12px;
        color: #333;
      }

      .el-select {
        margin-left: 30px;
      }
    }

    .button-section {
      display: flex;
      align-items: center;
    }
  }
}

.page .header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 16px;
  background-color: #fff;
}

.warp {
  // padding: 0 26px;
  background: #fff;
  border-radius: 8px;

  .warp-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #cccccc;
    font-size: 15px;
    font-weight: 500;
    color: #fff;

    span {
      border-left: 2px solid;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }
}

.page .header .el-input,
.page .header .el-button {
  margin-top: 5px;
  margin-bottom: 5px;
}

.header-search .search > .el-input__inner {
  width: 178px;
  border: 1px solid #34bbec;
  border-radius: 18px;
  margin-right: 16px;
}

.header-button {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.table-main .el-form--inline .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 48%;
}

.header-switc {
  display: flex;
  align-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 20px;
  font-size: 14px;
  color: #999999;
}

.header-switc-button {
  padding: 5px 6px;
  border: 1px solid #333;
  background-color: #ffffff;
  border-color: #34bbec;
}

.header-switc-button.header-switc-button-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.header-switc-button.header-switc-button-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.header-switc-button.active {
  background-color: #34bbec;
  color: #fff;
}

.header-switc-button:hover {
  background-color: #34bbec;
  border-color: #34bbec;
  color: #fff;
}

.dialog-table tr th,
.dialog-table tr td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.table-page {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

/deep/ .el-table__empty-text {
  background-position: 50px 0 !important;
}

.mr-10 {
  margin-right: 10px;
}
.dialog-export {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #cccccc;
    margin-top: 25vh;
  }
  ::v-deep .el-dialog__header .el-dialog__headerbtn {
    padding-top: 4px;
  }
  ::v-deep .el-dialog__header .el-dialog__title {
    color: #34bbec;
  }
  ::v-deep .el-dialog__body {
    text-align: center;
    font-size: 16px;
    padding-top: 15px;
  }
  .export {
    background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngdc4af7eaced9daa7717a57e77ac1cfffe0a97d5ae756a8e5039d326c72162485) -1px
      0px no-repeat;
    margin-top: 14px;
  }
  ::v-deep .el-dialog__body .export-banb {
    // text-align: center;
    // font-size: 16px;
    margin-top: 15px;
  }
  ::v-deep .el-dialog__body .export-edition {
    display: flex;
    //  justify-content:space-evenly;
    // justify-content: center;
    align-items: center;
    // width: 350px;
    // height: 70px;
    height: 3.8667rem;
    border-radius: 6px;
    border: 1px solid rgba(52, 187, 236, 1);
    // background-color: rgba(239, 248, 251, 1);
    background-color: #EEE;
    margin: 1.8rem 0 1.8rem 0;
    // padding: 0 50px;
  }
  .export-edition:hover {
    background-color: rgba(239, 248, 251, 1);
  }
  .export-edition{
    width: 100%;
    display: flex;
    justify-content:space-between;
    cursor: pointer;
    .serase-word{
      display: flex;
      align-items: center;
      // margin-right: 2.4rem;
       position: relative;
      right: 1.8rem;
    }
  }
}
.banb-edition {
  // padding: 0 2.6667rem;
  // padding: 0 2.6667rem 0 3rem;
}
.info13 {
  // width: 48px;
  // height: 22px;
  // display: block;
  // display: flex;
  position: relative;
  left: 1.8rem;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  // white-space: nowrap;
  // line-height: 22px;
  // text-align: left;
  // margin-left: 10%;
}

.word21 {
  // width: 48px;
  // height: 17px;
  // display: block;
  overflow-wrap: break-word;
  color: rgba(52, 187, 236, 1);
  font-size: 12px;
  white-space: nowrap;
  line-height: 17px;
  text-align: left;
  // margin-left: 4.6rem;
  // margin-left: 38%;
  // margin-right: 2.4rem;
  margin-right: 1.2667rem;
  // margin: 11px 0 0 135px;
}
.outer22 {
  display: flex;
  //  justify-content:space-evenly;
  align-items: center;
  // width: 350px;
  // height: 70px;
  height: 3.8667rem;
  border-radius: 6px;
  // border: 1px solid rgba(52, 187, 236, 1);
  // background-color: rgba(239, 248, 251, 1);
  // .word21 {
      // margin-left: 32%;
  // }
}
.grid-content {
    border-radius: 4px;
    // min-height: 36px;
  }
</style>
